.axolotl-header {
    display: flex;
    flex-flow: column nowrap;
    background-color: white;
    width: 256px;
    top: 24px;
    left: 36px;
    position: fixed;
    z-index: 8;
    border-radius: 24px;
    height: calc(100vh - 48px);
    transition: 200ms ease opacity;
    opacity: 1;
    
    > img {
        padding: 25px 40px 20px;
    }

    > .robot-switch-container {
        width: 100%;
        padding: 10px 45px;
        .media {
            width: 100%;
            .switch {
                width: 100%;
                input:checked + .switch-state:before {
                    transform: translate(130px);
                }
            }
        }
    }
    
    > .nav-links-container {
        display: flex;
        flex-flow: column nowrap;
        max-height: calc(100% - 90px);
        overflow: auto;
        position: relative;

        > .section-nav-link {
            height: 70px;
            color: #242223;
            padding-left: 40px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: relative;

            .active-notch {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 10px;
                background-color: #F37621;
                border-top-right-radius: 9999px;
                border-bottom-right-radius: 9999px;
                transition: 300ms ease opacity;
            }

            > p {
                font-size: 17px;
                font-weight: 500;
                margin-left: 20px;
            }
            
            &.active, &:hover {
                .active-notch {
                    opacity: 1;
                }
                > p {
                    color: #F37621;
                    font-weight: 600;
                }
            }
        }
    }

    > .logout-and-help-buttons {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 50px;
        width: 100%;

        >.bars-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 9999px;
            height: 36px;
            width: 36px;
            border: 1px solid #6b1703;
            margin-right: 10px;
            cursor: pointer;
            
            > svg {
                color: #6b1703;
            }
            
            &:hover {
                border: 1px solid #F37621;
                > svg {
                    color: #F37621;
                }
            }
        }
        > .log
        on, > .help-button {
            width: 40px;
            height: 40px;
            &:hover {
                cursor: pointer;
            }
        }

        > .logout-button {
            height: 36px;
            margin-right: 10px;
        }
    }

    &.closed {
        opacity: 0;
        width: 0;

        >img {
            display: none;
        }
    }
}

body.dark-only {
    .axolotl-header {
        background-color: #121726;
        >.nav-links-container {
            >.section-nav-link {
                color: #ffffff;
            }
        }
        > .logout-and-help-buttons > .bars-toggle {
            border: 1px solid white;
            >svg {
                color: white;
            }
        }
    }
}