.payment-method-setting {
    .card-body {
        padding: 70px;
    }

    .axolotl-payment-methods {
        .axolotl-bank-account-item {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
    
            .options-dropddown-toggle {
                cursor: pointer;
                padding: 10px 16px;
                margin-right: -15px;
                border-radius: 5px;
                transition: all 0.3s ease;
    
                &:hover {
                    background-color: rgba(#F37621, 0.1);
                }
            }
        }
    }
}
