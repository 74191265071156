.buy-modal {
    .modal-content {
        border-radius: 24px;
        display: flex;
        flex-flow: row nowrap;
        .content {
            background-image: linear-gradient(
                to bottom,
                rgba(241, 116, 32, 0.21) 0%,
                rgba(254, 220, 198, 0) 20%,
                rgba(254, 220, 198, 0) 100%
            );
            border-top-left-radius: 24px;
            width: 60%;
            padding: 30px;
        }

        > img {
            width: 40%;
        }
    }

    &.no-image {
        .content {
            width: 100%;
        }

        > img {
            width: 0;
        }
    }
}

@media screen and (max-width: 1000px) {
    .modal-content {
        .content {
            width: 100% !important;
        }
        >img {
            display: none;
            width: 0;
        }
    }
}