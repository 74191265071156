/* Crypto card */
div.axolotl-crypto-card {
    padding: 20px;
    position: relative;
    transition: box-shadow ease 200ms;

    img {
        width: 100%;
        height: auto;
        z-index: 1;
    }
    
    .text {
        position: absolute;
        height: calc(100% - 40px);
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        
        p {
            font-size: 20px;
            color: white !important;
            z-index: 2;
        }
    }
}

/* User Top Component */
.axolotl-sidebar {
    img.rounded-circle {
        border: 10px solid rgba($primary-color, 0.2) !important;
    }

    /* Balances text */
    h5 {
        font-weight: bold;
        color: $primary-color;
    }
    p {
        color: $primary-color !important;  // replace template dark mode color 
    }

    .axolotl-chervron-right {
        color: $primary-color;
    }    
}
body.light-only .axolotl-sidebar {
    /* Balances text */
    h5 {
        color: black;
    }
}

/* Sidebar route items */
.axolotl-sidebar-items {
    .nav-link.menu-title {
        border-radius: 9999px !important;
        
        &:hover, &.active {
            color: white !important;
            background-color: $primary-color !important;
            background-image: linear-gradient(
                $background-gradient-primary-top 0%,
                $background-gradient-primary-bottom 100%
            );
        }
    }
}