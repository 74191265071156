table.table {
    .btnTable {
        width: 152px;
        height: 38px;
        margin: 0 0 0 45px;
        padding: 9px 32px 15px 31px;
        border-radius: 10px;
        border: solid 1px #F37621;
        background-color: rgba(105, 60, 36, 0.21);
    }

    thead {
        tr {
            background-image: linear-gradient(to bottom, rgba(197, 95, 26, 0.4) 0%, rgba(255, 167, 108, 0.7) 100%);

            > th {
                background-color: transparent !important;
            }
        }
    }

    tbody {
        tr:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            &.dark {
                box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
            }
        }
        td {
            border: none !important;
            vertical-align: inherit;
        }

        tr {
            border: none !important;
        }
    }
    th {
        border: none !important;
    }

    .coin-type-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-height: 50px;

        svg {
            width: 35px;
            height: 35px;
            margin-right: 20px;
        }
    }
    .elementsTable {
        white-space: nowrap;
    }
    .no-data-text {
        text-align: center;
        padding: 20px;
        color: grey;
    }
}

body.dark-only {
    table.table {
        thead {
            tr {
                background-image: linear-gradient(to bottom, rgba(197, 95, 26, 1) 0%, rgba(246, 127, 47, 1) 100%);

                >th {
                    background-color: transparent !important;
                }
            }
        }
    }
}