.buy-modal.receipt-modal {
    .content {
        display: flex;
        flex-flow: row nowrap;
        padding: 150px 0;

        > .title-container {
            width: 55%;

            > .title, >.result-value-box {
                font-size: 24px;
                font-weight: bold;
                color: #f24620;
                text-transform: uppercase;
            }

            > .title {
                margin-bottom: 20px;
            }
            
            > .result-value-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                font-size: 40px;
                border-radius: 9999px;
                border: 1px solid rgba(240, 117, 32, 0.48);
                background-image: linear-gradient(
                    to bottom, 
                    rgba(#f17420, 0.25) 0%, 
                    rgba(#fcb520, 0.25) 100%
                );
                padding: 0 30px;
            }

            > .equivalent {
                font-size: 24px;
                color: black;
                margin-top: 20px;
            }
        }
        > .details-container {
            width: 45%;
            padding-right: 40px;

            > .transaction-id {
                font-size: 18px;
                color: #2c323f;
                margin-bottom: 20px;
            }

            > .group-text {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                white-space: nowrap;

                > * {
                    width: 50%;
                    &:first-child {
                        margin-right: 20px;
                    }
                    &:last-child {
                        color: #72757c;
                    }
                }

                &.total-orange, > &.total-orange > *:last-child {
                    font-weight: bold;
                    color: #f24620;
                }
            }

            > .divider-gray {
                width: 100%;
                border-bottom: 1px solid #ebf0ee;
                margin: 20px 0;
            }

            > .divider-orange {
                width: 80%;
                border-bottom: 1px solid #f17420;
                margin: 15px 0;
            }
        }
    }
}

body.dark-only {
    .buy-modal.receipt-modal {
        .content {
            .title-container {
                >.equivalent {
                    color: white;
                }
            }
            .details-container {
                .transaction-id {
                    color: white;
                }
            }
        }
    }
}