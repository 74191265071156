.border-tab.nav-primary.nav.nav-tabs {
    .nav-item {
        .nav-link {
            border-bottom: 2px solid rgba($primary-color, 0.2);

            &.active {
                color: $primary-color !important;
                border-bottom: 2px solid $primary-color;
            }
            &:hover {
                color: $primary-color !important;
            }
        }
    }
}