.axolotl-market-container {
  > .main-container {
    > .graphic-card-container {
      min-width: 55vw;
    }

    .card {
      margin-bottom: 10px;
    }
  }

  .text-dropdown {
    .options-dropddown-toggle {
      padding-left: 10px !important;
      border: 1px solid #f2f2f2;

      >p {
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .axolotl-market-container {
    margin-left: 130px;
  }

  .responsive-sidebar {
    margin-left: 0px;
  }
}