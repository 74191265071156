.help-center {
  .accordion {
    hr {
      margin-left: 45px;
      margin-top: 10px;
    }

    .accordion-header {
      margin-left: 40px;

      .row {
        display: flex;
        align-items: center;

        .icon {
          display: flex;
          justify-content: flex-end;

          i {
            color: #F37621;
            font-size: 25px;
          }
        }
      }
    }

    .accordion-button {
      border: none !important;
      background-color: inherit;
      width: 100%;

      p {
        flex-grow: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        color: #72757c;
      }
    }
  }

  .accordion-body {
    margin-left: 45px;
    margin-bottom: 35px;
    flex-grow: 0;
    font-size: 15px;
    letter-spacing: normal;
    color: #72757c;

    p {
      text-align: left;
    }
  }
}