.text-dropdown {
    .dropdown-menu {
        >button.dropdown-item {
            &:active {
                background-color: $primary-color;
            }
        }
    }

    .options-dropddown-toggle {
        &:hover {
            background-color: rgba($primary-color, 0.2) !important;
        }
    }
}