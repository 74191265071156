.input-amount {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border: 1px solid #d2ad94;
    border-radius: 19px;
    padding: 20px 40px;
    > input {
        border-radius: 0;
        border-top-left-radius: 19px;
        border-top-right-radius: 19px;
        border: none;
        height: 70px;
        border-bottom: 1px solid #f24620;
        color: #f24620;
        font-weight: bold;
    }
    
    > .amount-text {
        margin: 10px 0
    }
    
    > .equivalent {
        color: #f24620;
        font-weight: bold;
    }
}

// HIDE DEFAULT ARROWS

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}