@import 'exchange-coins-button';

.wallet-convert-cryptos {
    .text-dropdown {
        .dropdown-menu {
            button.dropdown-item {
                &:active {
                    background-color: $primary-color;
                }
            }
        }
    }
}