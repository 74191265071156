.axolotl-text {
    font-family: Inter, sans-serif;
    > a {
        color: $primary-color !important;
    }

    /* Colors */
    &.color-success {
        color: $success;
    }
    &.color-danger {
        color: $danger;
    }
    &.color-gray {
        color: $secondary-color;
    }
    &.color-primary {
        color: $primary-color;
    }
    &.color-info {
        color: $primary-color;
    }
    &.color-light {
        color: $primary-color;
    }

    /* Other */
    &.clickable {
        cursor: pointer;
    }
}

.axolotl-link-text {
    color: white;
    text-decoration: underline;
    
    &:hover {
        color: white;
    }
}