.axolotl-toast-banner {
    width: 100%;
    border-radius: 10px;
    background-color: #ebf0ee;
    padding: 10px 18px;
    display: none;
    color: #F37621;

    &.show {
        display: block;
    }
}

body.dark-only {
    .axolotl-toast-banner {
        color: #ffb17d;
        background-color: rgba(#F37621, 0.2);
    }
}