.axolotl-payment-method-edition {
    .card {
        .card-body {
            padding: 70px;

            .axolotl-form-input {
                > input {
                    border-radius: 9999px;
                    border: solid 1px #d2ad94;
                }
            }
        }
    }

    .title {
        font-size: 23px;
        color: #f17420;
        text-transform: uppercase;
        font-weight: bold;
    }
    
    .pin-code-input {
        .react-code-input {
            input {
                margin: 0px !important;
                height: 45px !important;
                margin-right: 8px !important;
                background-color: #fff !important;
                border-color: #d2ad94 !important;
                color: #717171 !important;
                border-radius: 9999px !important;
            }
        }

        &.dark {
            .react-code-input {
                display: flex;

                input {
                    background-color: transparent !important;
                    background-color: #111727 !important;
                    color: rgba(255, 255, 255, 0.7) !important;
                    border-color: #d2ad94 !important;
                }

                input:focus {
                    box-shadow: 0 0 0 0.2rem rgb(171 140 228 / 5%) !important;
                }
            }
        }
    }
}