@import 'palette';

/* Routes */
@import 'screens/session';
@import 'dashboard';
@import 'market';
@import 'conversor';
@import 'profile';
@import 'help';
@import 'operator';

/* Other */
@import 'layout';
@import 'common';

#root, body, html {
    height: 100%;
    width: 100%;
}

body {
    overflow: hidden;
}

body.dark-only {
    .page-wrapper {
        .page-body-wrapper {
            background-color: #202938;
        }
    }
}