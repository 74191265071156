.editable-image {
    position: relative;
    padding-left: 10px;
    > .bordered-image {
        width: 120px;
        height: 120px;
        border-radius: 9999px;
        border: 10px solid rgba(241, 116, 32, 0.32);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    
        > img {
            width: 110px;
            background-size: cover;
            border-radius: 9999px;
        }
    }

    > .edit-button:hover {
        // cursor: pointer;
    }

    > .edit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: white;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
        border-radius: 9999px;
        position: absolute;
        bottom: 10px;
        left: 0;
        transition: box-shadow ease 200ms;

        >svg {
            // width: 20px;
            // height: 20px;
            // color: #F37621;
        }

        &:hover {
            // cursor: pointer;
            // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
        }
    }

    .wrapper {
        // display: inline-block;
        // position: relative;
    }

    input[type=file] {
        opacity: 0;
        position: absolute;
        cursor: pointer;
    }
}