.operator-orders-tabs {
    .nav-tabs {
        .nav-item {
            white-space: nowrap;
        }
    }
}

@media screen and (max-width: 575px) {
    .operator-orders-tabs {
        .nav-tabs {
            display: flex;
            flex-flow: column nowrap;
        }
    }
}