.session-card {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    >.container {
        margin: auto 10%;
        background-color: white;
        border-radius: 24px;
        padding: 75px;
        position: relative;
        max-height: 90%;
        overflow: auto;
        
        >.top-border {
            position: absolute;
            top: 0;
            left: 0;
            height: 23px;
            width: 100%;
            background-image: linear-gradient(to bottom, rgba(241, 116, 32, 0.29) 0%, #fedcc6 100%);
            border-top-right-radius: 9999px;
            border-top-left-radius: 9999px;
        }

        >.title-and-subtitle {
            display: flex;
            flex-flow: column nowrap;
            margin-bottom: 20px;

            .title {
                color: #f24620;
                font-size: 26px;
                text-transform: uppercase;
                font-weight: bold;
            }

            .subtitle {
                font-size: 18px;
            }
        }

        .axolotl-text.clickable {
            font-weight: bold;
            color: #72757c;
        }

        .buttons {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            padding-top: 70px;

            >button {
                width: 300px;
                margin-bottom: 20px;
            }

            >div {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                font-weight: 600;
                font-family: Inter, sans-serif;
                font-size: 15px;

                >p {
                    color: #ee8036;
                    text-decoration: underline;
                    margin-left: 5px;
                    font-size: 15px;
                }
            }
        }
    }

    >img {
        height: 100%;
        max-width: 50%;
    }
}

@media screen and (max-width: 1200px) {
    .session-card {
        > .container {
            padding: 75px 30px;
        }
        > img {
            width: 0;
        }
    }
}