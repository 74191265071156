.market-input-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    min-width: 250px;
    border-radius: 9999px;
    border: 1px solid #ebf0ee;
    
    &.disabled {
        >input {
            background-color: #f7f7f7;
            color: #444444;

            &::placeholder {
                color: #444444 !important;
            }
        }

        >label {
            color: #444444;
        }
    }

    &.disabled.dark {
        >input {
            color: white !important;
            font-weight: 600 !important;
            background-color: rgba(255, 255, 255, 0.06) !important;

            &::placeholder {
                color: #ffffff !important;
                font-weight: 600 !important;
            }
        }

        >label {
            color: #ffffff;
        }
    }

    input {
        padding: 0 55px;
        text-align: right;
        border-radius: 9999px;
    }

    >label {
        margin: 0;
        position: absolute;

        &.prefix-label {
            left: 10px;
        }

        &.suffix-label {
            right: 10px;
        }
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}