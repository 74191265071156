.axolotl-crypto-card-item > .card > .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20%;
    min-height: 130px;

    h4 {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    button {
        margin-top: 8px;
    }
}