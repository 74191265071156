.landing {
    height: 100%;

    .header {
        width: 100%;
        position: fixed;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        height: 90px;
        background-color: white;
        padding: 0 20px;
        box-shadow: 0 0 50px 0 rgba(black, 0.4);
        z-index: 1;

        > img {
            height: 70px;
            margin-right: 20px;
        }

        .link-container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            > button {
                width: 180px;
            }
        }
    }

    .content {
        height: 100%;
        width: 100%;
        overflow-y: auto;

        .top {
            position: relative;
            width: 100%;

            > img {
                width: 100%;
            }
    
            .floating-card {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                position: absolute;
                min-height: 200px;
                max-height: calc(100% - 90px);
                bottom: -100px;
                right: 10%;
                width: 80%;
                background-color: white;
                padding: calc(30px + 2%) 20%;
                border-radius: 24px;
                box-shadow: 0 0 20px 0 rgba(black, 0.1);
                
                > h1 {
                    text-align: center;
                    color: #b52100;
                }
    
                > button {
                    margin-top: 20px;
                    width: 180px;
                }
            }
        }
    
        .middle {
            background-color: #d3d3d323;
            padding: 200px 10%;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;

            .texts {
                text-align: center;
                > .title {
                    text-transform: uppercase;
                    color: #f24620;
                    font-size: 40px;
                }
                > .subtitle {
                    font-size: 24px;
                }
            }

            .images-cards {
                display: flex;
                flex-flow: row nowrap;
                margin-top: 50px;

                > .image-card {
                    padding: 5%;
                    text-align: center;

                    > img {
                        width: 250px;;
                        border-radius: 9999px;
                        margin-bottom: 10px;
                    }

                    > .title {
                        font-weight: bold;
                        font-size: 28px;
                    }
                    > .subtitle {
                        font-size: 18px;;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid rgba(black, 0.1);
                    }
                }
            }
        }
        
        .bottom {
            background-color: white;

            > .top-content {
                display: flex;
                flex-flow: row wrap;
                padding: 70px;

                > .title-and-button {
                    flex: 1;
                    > p {
                        font-size: 35px;
                        line-height: 40px;
                        margin-bottom: 30px;
                    }
                    > button {
                        width: 180px;

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                }

                > .link-columns {
                    display: flex;
                    flex: 3;
                    flex-flow: row nowrap;
                    justify-content: space-between;

                    > .column {
                        margin: 0 20px;

                        > .title {
                            font-weight: bold;
                            font-size: 23px;
                        }

                        > .link {
                            cursor: pointer;
                            font-size: 20px;
                        }
                    }
                }
            }


            > .social {
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #A4635F;
                
                > * {
                    color: white;
                    cursor: pointer;
                }

                > :not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .landing {
        .content {
            .middle {
                .images-cards {
                    flex-flow: column nowrap;
                    >.image-card {
                        &:not(:last-child) {
                            border-right: none;
                            border-bottom: 1px solid rgba(black, 0.1);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .landing {
        .header {
            flex-flow: column nowrap;
            height: unset;
            padding: 20px;
            .link-container {
                flex-flow: column nowrap;
            }
        }

        .content {
            .top {
                padding-top: 200px;
            }
            .bottom {
                >.top-content {
                    flex-flow: column nowrap;
            
                    >.link-columns {
                        flex: 1;
                        flex-flow: column nowrap;
            
                        >.column {
                            margin: 20px;
                        }
                    }
                }
            }
        }
    }
}
