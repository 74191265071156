.help-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    .card {
        width: 100%;

        > .card-body {
            background-image: linear-gradient(
                to bottom,
                rgba(241, 116, 32, 0.21) 0%,
                rgba(254, 220, 198, 0) 20%,
                rgba(254, 220, 198, 0) 100%
            );
            padding: 40px 70px;
    
            > .title {
                font-size: 24px;
                color: #f24620;
                margin-top: 20px;
                margin-bottom: 20px;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }
}