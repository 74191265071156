.axolotl-tabs {
    display: flex;
    flex-flow: row nowrap;
    li.nav-item {
        list-style-type: none;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        min-width: 170px;
        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 30px;
            height: 37px;
            width: 100%;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
            color: black;
            white-space: nowrap;
            &:not(.active) {
                border-bottom: 3px solid #fee3bb;
            }
            &.active {
                color: white;
                background-image: linear-gradient(
                    to bottom,
                    #FB9515 0%,
                    #F35D0A 100%
                );

            }
        }

    }
}

body.dark-only {
    li.nav-item {
        .nav-link {
            color: white;
            &:not(.active) {
                border-bottom: 3px solid #725a37;
            }
        }
    }
}