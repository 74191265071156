header {
    width: 100%;
    height: 120px;
    position: relative;

    > img {
        background-size: cover;
        width: 100%;
        height: 100%;
        border-bottom: 2px solid rgba(229, 229, 229, 0.11);
    }

    > .floating-content {
        position: absolute;
        right: 5%;
        top: 30px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        > .buttons {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: 36px;
            margin-right: 10px;

            > .bars-toggle {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 9999px;
                height: 36px;
                width: 36px;
                border: 1px solid white;
                margin-right: 10px;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 0 10px 0 rgba(white, 0.5);
                }
            }

            > svg {
                cursor: pointer;
                height: 36px;
                width: 36px;
                transition: 150ms ease box-shadow;
                border-radius: 9999px;
                &:hover {
                    box-shadow: 0 0 10px 0 rgba(white, 0.5);
                }
            }
        }
    
        > .user-bubble {
            > img {
                width: 44px;
                height: 44px;
                border: 1px solid white;
                border-radius: 9999px;
            }
    
            height: 60px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            border-radius: 24px;
            padding: 10px;
            padding-right: 50px;
            border: solid 1px #ececec;
            background-image: linear-gradient(
                to bottom,
                rgba(white, 0.6) 0%, 
                rgba(white, 0.5) 100%
            );
    
            > .balances {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                margin-left: 10px;
                padding-top: 8px;
    
                > .balance {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 18px;
                }
                > .equivalent-balance {
                    color: #2c323f;
                    font-size: 13px;
                }
            }
        }
    }
}