.axolotl-market-header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    display: flex;
    align-items: center;
    transition: 0.5s;
    background-color: #fff;
    box-shadow: 0 0 20px rgba($primary-color, 0.1);
    height: 85px;
    border-bottom: 1px solid rgba($primary-color, 0.3);

    > .brand-container {
        height: 100%;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba($primary-color, 0.3);
        cursor: pointer;

        > img {
            height: auto;
            width: 110px;
        }
    }

    >.balance-container {
        display: flex;
        flex-flow: row nowrap;
        margin-left: 20px;
    }

    .status_toggle {
        cursor: pointer;
    }

    >.loader-wheel-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
    }
}

body.dark-only .axolotl-market-header {
    background-color: #111727;
    border-right: none;
}