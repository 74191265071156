header.axolotl-market-sidebar {
    position: fixed;
    border-bottom: none;
    top: 83px;
    z-index: 8;
    height: 100%;
    background: #fff;
    border-right: 1px solid #e6edef;
    width: 130px !important;
    transition: 0.3s ease;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 20px;

    > button {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 50px;
        margin-bottom: 15px;
    }
}

body.dark-only header.axolotl-market-sidebar {
    background-color: #111727;
    border-right: none;
}