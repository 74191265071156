.slick-carousel {
    > .slick-prev{
        left: -30px;
        top: 46%;

        &:before {
            color: #F37621;
            font-size: 30px;
        }
    }
    >.slick-next {
        right: 30px;
        top: 46%;

        &:before {
            color: #F37621;
            font-size: 30px;
        }
    }
    > .slick-dots {
        bottom: -15px !important;
        > li > button:before {
            color: #F37621;
            font-size: 15px;
        }
    }
}