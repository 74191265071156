.axolotl-form-input {
    position: relative;
    .password-error-container {
        display: flex;
        flex-flow: row wrap;
    }
    .react-tel-input {
        .form-control {
            width: inherit;
            border: 1px solid #e6edef;
            border-radius: 0.25rem;
        }
        .flag-dropdown {
            background-color: #e7edef;
        }
    }
    .disabled {
        .selected-flag {
            cursor: not-allowed;
        }
        .form-control {
            color: #111727;
            background-color: #e9ecef;
        }
    }
    .phone-input-dark {
        .form-control {
            background-color: #111727;
            border: 1px solid rgba(105, 60, 36, 0.2);
        }
        .flag-dropdown {
            background-color: #111727;
            border: 1px solid rgba(105, 60, 36, 0.2);
        }
        .selected-flag {
            &:not(:hover) {
                background-color: #111727;
            }

            &:hover {
                background-color: #111727;
            }
        }
        .country-list {
            background-color: #111727;
            border: 1px solid rgba(105, 60, 36, 0.2);
            color: #fff;
        }
        .country.highlight {
            background-color: #000;
        }
        .country {
            &:hover {
                background-color: #000;
            }
        }
    }
    .phone-input-dark.disabled {
        .form-control {
            color: white !important;
        }
    }

    /* UI Redesign */

    > input.form-control {
        height: 45px;
        border-radius: 10px;
        padding-left: 55px;
    }

    > label.form-label {
        font-size: 18px;
        margin-bottom: 10px;
    }

    > .show-hide {
        top: 60px;
        font-size: 14px;
        > .show, > .hide {
            color: #72757c;
        }
    }

    > .left-icon {
        position: absolute;
        left: 20px;
        top: 46px;
    }

    > .react-code-input {
        display: flex !important;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        > input {
            height: 45px !important;
            width: 55px !important;
            border-radius: 10px !important;
            box-shadow: none !important;
            border: 1px solid rgba(black, 0.1) !important;
            font-size: 30px !important;
            padding-left: 17px !important;
        }
    }
}
