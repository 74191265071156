.buy-modal {
    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        H4 {
            margin-bottom: 5;
            font-weight: bold;
        }
    }

    .close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 25px;
        right: 25px;
        width: 35px;
        height: 35px;
        z-index: 2;
        border-radius: 5px;
        transition: background-color ease 500ms;

        &:hover {
            background-color: rgba(36, 105, 92, 0.1);
            cursor: pointer;
        }
    }

    .modal-content {
        border: none !important;

        .card-buy {
            .card {
                border: none;
                margin-bottom: 0;
                width: auto;
            }
        }
    }
}
