.wallet-card {
    min-width: 70%;

    .card-body {
        overflow-x: auto;
    }

    .wallet-card-buttons {
        display: flex;
        flex-flow: row nowrap;

        >*:not(:last-child) {
            margin-right: 10px;
        }
    }

    button {
        white-space: nowrap;
    }
}

.wallet-modal-open {
    .modal-header {
        flex-grow: 0;
        font-size: 18px;
        font-weight: bold;
        border-bottom: none;
        color: #2c323f;
        margin: 10px 0 0 30px;
    }

    .react-code-input {
        input {
            margin: 0px !important;
            height: 36px !important;
            margin-right: 8px !important;
            background-color: #fff !important;
            border-color: #e6edef !important;
            color: #717171 !important;
            box-shadow: none !important;
        }
    }

    &.dark {
        .react-code-input {
            display: flex;

            input {
                margin: 0px !important;
                height: 36px !important;
                margin-right: 8px !important;
                background-color: rgba(36, 105, 92, 0.2) !important;
                border-color: rgb(8 24 21 / 20%) !important;
                color: rgba(255, 255, 255, 0.7) !important;
            }

            input:focus {
                box-shadow: 0 0 0 0.2rem rgb(171 140 228 / 5%) !important;
            }
        }
    }

    .deposit-modal-container {
        padding: 10px 30px !important;

        .modal-mxn-data {
            strong {
                font-size: 14px;
            }

            p {
                margin-bottom: 10px;
            }
        }

        .modal-crypto-dropdown {
            margin-bottom: 10px;

            .options-dropddown-toggle {
                padding-left: 0 !important;
                border: none;
            }

            strong {
                font-size: 15px;
            }
        }
    }

    .text-dropdown {
        .options-dropddown-toggle {
            padding-left: 10px !important;
            border: 1px solid #f2f2f2;

            >p {
                margin-right: 10px;
            }
        }
    }
}