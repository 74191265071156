button.axolotl {
    /* Normalize */
    outline: none;
    border: none;
    background: transparent;
    height: 45px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    padding: 0 20px;
    white-space: nowrap;
    font-weight: 500;
    user-select: none;

    /* Sizes */
    &.size-sm {
        height: 35px;
    }
    &.size-bg {
        height: 60px;
        padding: 0 40px;
    }

    /* Colors */
    &.color-primary {
        color: white;
        background-image: linear-gradient(
            $background-gradient-primary-top 0%,
            $background-gradient-primary-bottom 100%
        );
    }
    &.color-secondary {
        color: white;
        background-image: linear-gradient(
            $background-gradient-secondary-top 0%,
            $background-gradient-secondary-bottom 100%
        );

        &.outline {
            color: $secondary-color;
            border: 2px solid $secondary-color;
            background-image: none;
            
            &:hover {
                background-color: rgba($secondary-color, 0.2);
            }
        }
    }
    &.color-success {
        background-color: $success;
    }
    &.color-danger {
        color: white;
        background-color: $danger-button;
    }
    &.color-gray {
        color: white;
        background-color: $secondary-color;
    }
    &.color-transparent {
        background-color: transparent;
        color: white;
    }
    &.color-dark {
        background-color: transparent;
        color: black;
    }

    /* Other */
    &.outline {
        color: $primary-color;
        border: 2px solid $primary-color;
        background-image: none;
        transition: background-color ease 200ms;

        &:hover {
            background-color: rgba($primary-color, 0.2);
        }
    }

    &.disabled {
        cursor: default;
    }
}

body.dark-only {
    button.axolotl {
        &.color-secondary {
            &.outline {
                color: white;
            }
        }
    }
}