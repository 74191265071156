/* toggle sidebar */
div.axolotl-toggle-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;

    svg {
        color: rgba($primary-color, 0.75);
    }

    &:hover {
        background-color: rgba($primary-color, 0.1);
    }
}