.axolotl-wallet-balance {
    margin: 15px;
    border-radius: 18px;
    display: flex;
    flex-flow: row nowrap;
    border: 1px solid #e6edef;
    height: 200px;

    .left-image {
        > img {
            height: 200px;
            width: auto;
        }
    }

    .right-content {
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        height: 100%;
        max-width: 500px;
        background-color: white;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        padding: 0 30px;

        > .title {
            font-size: 20px;
            font-weight: bold;
        }
        
        > .balance {
            font-weight: 700;
            color: rgb(114, 117, 124);
            width: 100%;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

body.dark-only {
    .axolotl-wallet-balance {
        border: 1px solid #252c3c;
        > .right-content {
            background-color: #111727;
        }
    }
}