.crypto-card-container {
    min-width: 300px;
    max-width: 350px;
    height: 127px;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    overflow: hidden !important;
    background-color: white;
    padding: 20px;
    border-radius: 24px;
    border: solid 1px #ececec;
    box-shadow: 0 4px 50px 0 rgba(139, 139, 139, 0.06);
    margin-right: 15px;
    margin-bottom: 15px;
    
    > .icon {
        width: 54px;
        height: 54px;
    }
    > .texts {
        margin-left: 10px;
        > .price {
            font-size: 24px;
            line-height: 22px;
            font-weight: bold;   
        }
        > .coin {
            font-size: 16px;
            color: #72757c;
        }
    }
    > .floating-percentage {
        position: absolute;
        bottom: 10px;
        right: 10px
    }

    > .loader-box {
        margin-left: 40%;
    }
}

body.dark-only {
    .crypto-card-container {
        background-color: #121726;
        border: #2a2f3e;
    }
}