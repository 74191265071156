.market-wallet-card {
    margin: 0 -15px;

    .card {
        margin-bottom: 10px;
    }

    .card-body {
        overflow-x: auto;
        padding: 30px 30px 0 30px;
        max-height: 215px;

        .coin-cell {
            white-space: nowrap;
        }
    }
}