.quotation-table {
  .card {
    .card-header {
      border-bottom: none !important;
      padding: 30px 30px 0 30px !important;
      margin: 10px 5px 10px 19px;

      h5 {
        font-weight: bold;
        font-size: 21px;
      }
    }
  }

  .history-btns {
    display: flex;

    .btn1 {
      background-color: rgba(105, 60, 36, 0.21) !important;
      color: #F37621;
    }

    .btn2 {
      background-color: rgba(180, 139, 100, 0.15) !important;
      margin-left: 10px;
      color: #977452;
    }
  }

  td {
    vertical-align: middle;
    min-width: 90px;
  }

  .quotation-buy-sell-buttons {
    width: 420px;
    margin: 5px 0 5px 10px;

    >.input-and-buttons {
      height: 35px;
      display: flex;
      justify-content: center;
      margin-right: 0 !important;

      >.form-input {
        >input {
          width: 140px;
          padding-left: 20px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          margin-bottom: 5px;
        }
      }

      >button {
        height: 35px;

        &.buy-button {
          margin-right: 10px;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }

        &.sell-button {
          width: 150px;
        }
      }
    }

    .linked-text {
      >p {
        margin: 5px 0 0 10px;
        font-size: 11px;
        margin-bottom: 2px;
        text-decoration: underline;
      }
    }
  }
}