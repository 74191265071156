.profile-settings {
    .card {
        .card-body {
            padding: 70px;

            .profile-settings-main {
                display: flex;
                flex-flow: row nowrap;
                padding: 50px 0;
                border-bottom: 1px solid rgba(241, 116, 32, 0.19);
                .avatar-and-title {
                    display: flex;
                    flex: 1;
                    flex-flow: row nowrap;
                    align-items: center;
                    .names {
                        margin-left: 40px;
                        .name {
                            font-size: 30px;
                            color: #2c323f;
                            font-weight: bold;
                        }
                    }
                }
                
                .mail-and-password {
                    display: flex;
                    flex: 1;
                    flex-flow: column nowrap;
                    color: #72757c;

                    .label-text {
                        font-weight: bold;
                    }
                    .value {
                        margin-bottom: 30px;
                    }
    
                    .password-container {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;

                        .password {
                            margin-right: 10px;
                        }
                        .clickable {
                            color: #f17420;
                            margin-bottom: 3px;
                        }
                    }
                }
            }

            .user-settings-personal-data-container {
                margin-top: 30px;
                color: #72757c;
                .title {
                    font-size: 23px;
                    color: #f17420;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                .axolotl-form-input {
                    > input {
                        border-radius: 9999px;
                        border: solid 1px #d2ad94;
                    }
                }
                .axolotl-country-select-input {
                    > .form-control {
                        border-radius: 9999px;
                        border: solid 1px #d2ad94;
                        height: 45px;
                    } 
                }
                .phone-input-common {
                    height: 45px;
                    .react-tel-input {
                        height: 100%;
                        >input {
                            height: 100%;
                            border: solid 1px #d2ad94;
                            border-radius: 9999px;
                        }
                        > .flag-dropdown {
                            border: solid 1px #d2ad94;
                            border-top-left-radius: 9999px;
                            border-bottom-left-radius: 9999px;
                            > .selected-flag {
                                border-top-left-radius: 9999px;
                                border-bottom-left-radius: 9999px;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}

body.dark-only {
    .profile-settings {
        .card {
            .card-body {
                .profile-settings-main {
                    .avatar-and-title {
                        .names {
                            .name {
                                color: unset
                            }
                        }
                    }
                }
            }
        }
    }
}

.needs-validation {
    .profile-phone-input {
        &.dark {
            .form-control {
                border: 1px solid rgba(79, 211, 185, 0.2);
            }
        }
    }
}