.buy-modal.confirm-modal {
    .content {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(
            to bottom,
            rgba(241, 116, 32, 0.21) 0%,
            rgba(254, 220, 198, 0) 20%,
            rgba(254, 220, 198, 0) 100%,
        );
        > .top-text {
            margin-bottom: 20px;
        }
        > .cardboard-values {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 0 55px;
            min-height: 85px;
            border: solid 1px rgba(240, 117, 32, 0.48);
            background-image: linear-gradient(
                to bottom,
                rgba(#f17420, 0.4) 0%,
                rgba(#fcb520, 0.4) 100%
            );
            border-radius: 9999px;
            color: #f24620;
            font-size: 40px;
            font-weight: bold;
        }
    }
}