.send-consult {
    .card-body {
        /* form input */
        .axolotl-form-input {
            > label.form-label {
                font-size: 14px;
                font-weight: bold;
                color: #72757c;
            }
            > input,
            .axolotl-country-select-input > .form-control,
            > textarea {
                height: 40px;
                border-radius: 24px;
                border: solid 1px #d2ad94;
            }

            .axolotl-country-select-input>.form-control {
                cursor: pointer;
                margin-top: 5px;
            }

            > textarea {
                padding: 10px 15px;
                height: unset;
            }
        }
        /* form input files */
        .axolotl-form-input {
            .form-input-file-container {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                
                > .file-input {
                    width: calc(100% - 50px);
                    height: 40px;
                    border-radius: 24px;
                    > div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        border-radius: 24px;
                        border: solid 1px #d2ad94;
                    }
                }
                > label.file-label {
                    margin: 0;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    border-radius: 9999px;
                    background-color: rgba(#6b1703, 0.3) !important;
                }
            }
        }
    }
}