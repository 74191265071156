.preference-setting {
    .card-body {
        padding: 70px;
    }
}

.btn-column {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 991px) {
    .btn-column {
        height: 70px;
    }
}