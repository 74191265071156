.convert-coin-form {
  flex-flow: row nowrap;

  .form-input {
    margin-bottom: 0 !important;

    .form-control {
      border: none;
      width: 160px;
      padding: 5px 10px;
    }
  }

  .dropdown .options-dropddown-toggle {
    font-weight: bold;
  }

  .text-dropdown.dropdown > .options-dropddown-toggle > p {
    min-width: 35px;
  }

  .convert-coin-arrow {
    margin: 0 20px;
  }

  .button > button {
    margin-left: 20px;
    width: 180px;
  }
}