.close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25px;
    right: 25px;
    width: 35px;
    height: 35px;
    z-index: 2;
    border-radius: 5px;
    transition: background-color ease 500ms;

    &:hover {
        background-color: rgba(105, 60, 36, 0.1);
        cursor: pointer;
    }
}

.modal-content {
    border-radius: 16px;
}

.modal {
    top: 15%;
}