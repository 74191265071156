.axolotl-exchange-coins-button {
    top: 273px;
    background: #ebf0ee;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    color: $primary-color;
    
    &.disabled {
        cursor: default;
    }
}