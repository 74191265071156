.loader-box {
    &.white {

        .loader-7 {
            height: 25px;
            width: 25px;
        
            &:before,
            &:after {
                border: 0.2em solid white;
            }
        }
    }
}