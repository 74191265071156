.session-card {
    
    .buttons-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding-top: 20px;
        
        > button {
            width: 300px;
            margin-bottom: 10px;
        }
    }
}