.axolotl-dashboard-banner {
    max-width: 1700px;

    &.loaded {
        .card {
            background-image: url('../../axolotl/assets/images/new/Banner-01.png') !important;
            background-size: cover;
            background-position: center;
        }
    }

    .card {
        background-color: #F37621 !important;

        .card-body {
            padding: 15px 0 10px 8vw;
            .conversion-texts {
                color: white;

                .dropdown .options-dropddown-toggle {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    p {
                        margin-right: 10px;
                    }
                }
            }

            .balance-big-number {
                color: white;
                font-weight: 700;
            }

            .see-account-link-text {
                text-decoration: underline;
                color: #f5d1ae;
            }
        }
    }
}
