.total-balance {
    display: flex;
    flex-flow: row nowrap;
    height: 150px;
    width: 100%;
    
    .left-texts {
        height: 100%;
        display: flex;
        flex: 1;
        flex-flow: column nowrap;
        justify-content: center;
        padding-left: 50px;
        
        .title {
            font-size: 24px;
            font-weight: bold;
            color: #f24620;
            text-transform: uppercase;
        }
        .description{
            
        }
    }
    .right-balance {
        height: 100%;
        display: flex;
        flex: 2;
        flex-flow: row nowrap;
        align-items: center;
        
        > img {
            height: 90px;
            width: 90px;
        }

        .value {
            font-size: 50px;
            font-weight: bold;
            color: #72757c;
            margin-left: 20px;
        }
    }
}

@media screen and (max-width: 800px) {
    .total-balance {
        flex-flow: column nowrap;
        .right-balance {
            padding-left: 50px;
            margin-top: 10px;
        }
    }
}