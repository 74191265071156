.buy-modal.details-modal {
    .content {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;

        > .bordered-input {
            border: 1px solid #d2ad94;
        }

        > button {
            width: 180px;
        }
    }
}