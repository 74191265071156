header.axolotl-market-sidebar {
    position: fixed;
    border-bottom: none;
    top: 0;
    left: 30px;
    z-index: 8;
    height: 100%;
    background-color: white;
    border-right: 1px solid #E6EDEF;
    width: 120px !important;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 20px;
    border-radius: 24px;
    transition: 200ms ease opacity;
    opacity: 1;

    > img {
        height: 70px;
        width: 70px;
    }

    > .links {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        
        >.section-nav-link {
            width: 100%;
            height: 70px;
            color: #242223;
            padding-left: 50px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: relative;
    
            .active-notch {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 10px;
                background-color: #F37621;
                border-top-right-radius: 9999px;
                border-bottom-right-radius: 9999px;
                transition: 300ms ease opacity;
            }
    
            >p {
                font-size: 17px;
                font-weight: 500;
                margin-left: 20px;
            }
    
            &.active,
            &:hover {
                .active-notch {
                    opacity: 1;
                }
    
                >p {
                    color: #F37621;
                    font-weight: 600;
                }
            }
        }
    }

    &.closed {
        opacity: 0;
    }
}