.axolotl-dashboard-container {
    width: 100%;
    .top-content {
        width: 100%;
        flex-flow: row nowrap;
        margin-top: 20px;

        .carrousel-container {
            width: calc(100% - 540px);
        }
    }
    
    .dropdown-container {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: left;
        margin-bottom: 20px;
        margin-left: 40px;
    }

    .text-dropdown {
        .options-dropddown-toggle {
            padding-left: 10px !important;
            border: 1px solid #f2f2f2;
            margin-left: 10px;

            >p {
                margin-right: 10px;
            }
        }
    }

    .table-card {
        .top-content {
            display: flex;
            flex-flow: row nowrap;
            > .texts {
                display: flex;
                flex-flow: column nowrap;
                margin-left: 20px;
                > h1 {
                    color: #F37621;
                    font-weight: bold;
                    font-size: 21px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

// responsive for mobile
@media (max-width: 1100px) {
    .buy-btn {
        margin-top: 25px;
    }
}

@media screen and (max-width: 880px) {
    .axolotl-dashboard-container {
        .top-content {
            flex-flow: column nowrap;
            .carrousel-container {
                padding: 20px 40px;
                // margin-top: 20px;
                width: 100%;
            }
        }
    }
}