.second-step-register{

  .input-file {
    .input {
      display: none;
    }
  }

  #file-upload {
    display: none;
  }

  .file-input{
    p{
      font-weight: 400;
      text-align: left;
    }
  }

  .file-label:hover{
    cursor: pointer;
  }

  .file-label {
    margin-top: 15px;
    display: flex;
    width: 110px;
    height: 30px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: rgb(240, 237, 235) !important;

    p {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      color: #F37621;
    }
  }
}