.text-dropdown{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 35px;
  border-radius: 0.25rem;

  &.narrow-border-drp{
    width: fit-content;
  }

  &.dark{
    button:hover{
      background-color: #000;
    }
  }

  &.show-border {
    border: 1px solid #f2f2f2;
  }

  > .options-dropddown-toggle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    border-radius: 0.25rem;
  }
}
