.wallet-convert-cryptos {
    .card {
        .card-body {
            display: flex;
            flex-flow: row nowrap;
            
            form {
                width: 100%;
                .title-container {
                    padding-top: 20px;
                    padding-left: 20px;
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        color: #f24620;
                        text-transform: uppercase;
                    }
                }
            
                .from-row, .to-row {
                    padding: 20px 60px;
                    display: flex;
                    flex-flow: column nowrap;
                    
                    .input-container {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;

                        .prefix-text {
                            font-size: 25px;
                            color: #2c323f;
                            margin-right: 10px;
                        }

                        > input, .equivalent-to {
                            height: 45px;
                            padding: 0 20px;
                            width: 240px;
                            border-radius: 9999px;
                            border: solid 1px #d2ad94;
                            margin-right: 10px;
                        }

                        .equivalent {
                            font-size: 18px;;
                            color: #72757c;
                        }
                        
                        .equivalent-to {
                            display: flex;
                            align-items: center;
                            margin-left: 20px;
                            color: #72757c;
                        }
                    }

                    .use-all-balance-text {
                        text-decoration: underline;
                        margin-top: 10px;
                    }

                }

                .middle-divider {
                    width: 100%;
                    border-bottom: 1px solid #e5e5e5;
                    position: relative;
                    margin: 40px 0;

                    .switch-coin-button {
                        height: 65px;
                        width: 65px;
                        position: absolute;
                        top: -32.5px;
                        left: 45%;
                        background-color: #fee3bb;
                        border-radius: 9999px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #f17420;
                        cursor: pointer;

                        &.disabled {
                            cursor: default;
                        }
                        
                        svg {
                            transition: 200ms ease transform;
                        }

                        &:hover:not(.disabled) {
                            svg {
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
                
                .text-dropdown-container {
                    padding: 20px 60px;
                    
                    .text-dropdown {
                        height: 45px;
                        width: 240px;
                        border-radius: 9999px;
                        border: solid 1px #d2ad94;
                        margin-right: 10px;
                        
                        .options-dropddown-toggle {
                            cursor: pointer;
                            width: 100%;
                            border-radius: 9999px;
                            padding: 0 20px;
                            position: relative;
    
                            svg.svg-inline--fa.fa-angle-down {
                                position: absolute;
                                right: 20px;
                            }
                        }
                    }
                }

                .button-container {
                    padding: 20px 60px;
                    flex-flow: row-reverse nowrap;

                    > button {
                        width: 180px;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 880px) {
    .wallet-convert-cryptos {
        .card {
            .card-body {
                > img {
                    display: none;
                }
            }
        }
    }
}