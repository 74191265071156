.axolotl-help-modal {
    position: fixed;
    bottom: 25px;
    right: 25px;

    button.axolotl {
        width: 75px;
        height: 47px;
        border-radius: 24px !important;
        background-color: #ebe7e4;

        &.dark {
            background-color: #202938;
        }
    }

}
.axolotl-help-modal-open {
    .modal-header {
        flex-grow: 0;
        margin: 28px 0 0 35px;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: bold;
        border-bottom: none;
        color: #2c323f;
    }

    p {
        color: #72757c;
    }

    .modal-body {
        button.axolotl {
            width: 100%;
            border-bottom: 1px solid #72757c;
            border-radius: 0 !important;
            justify-content: flex-start;
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 30px;
            padding: 0 51px 30px 53px;

            &.last {
                border-bottom: none;
            }

            P {
                font-family: Roboto;
                font-size: 18px;
                font-weight: 300;
                color: #72757c;
            }
        }

    }
}