@import '../../../../../axolotl/assets/scss/palette';

.axolotl-balance-panel {
    position: relative;
    height: 272px;
    width: 485px;

    > img {
        width: 100%;
        height: 100%;
    }
    
    > .floating-content {
        position: absolute;
        top: 0;
        left: 0;
        padding: 50px;
        color: white;
        font-family: Montserrat, sans-serif;
        width: 100%;
        height: 100%;
        
        > .equivalent-text {
            font-size: 14px;
            font-weight: bold;
        }
        
        > .balance {
            font-size: 45px;
            line-height: 50px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        > .clickable {
            font-size: 14px;
            text-decoration: underline;
        }

        > .buttons {
            display: flex;
            flex-flow: row nowrap;
            padding-top: 30px;

            > * {
                width: 180px;

                &:first-child {
                    margin-right: 10px;
                }
            }

            > .white {
                color: $primary-color;
                background-image: none;
                background-color: white;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .axolotl-balance-panel {
        width: 100%;
    }
}