.deposit-card {
    height: 750px;

    .login-form {
        padding-top: 5px !important;
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        height: 45px;

        .btn {
            background-color: #ebe7e4;
            margin: 10px 30px 0 0;
            height: 35px;
            color: #977452;
        }
    }

    .title {
        font-size: 24px;
        color: #2c323f;
    }

    .subtitle {
        font-size: 14px;
        color: #72757c;
        text-align-last: center;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .results {
        width: 65%;
        margin-top: 20px;

        .row {
            width: 560px;
        }

        span {
            font-family: Roboto;
            font-size: 27px;
            font-weight: normal;
            color: #72757c;
        }

        p {
            font-family: Roboto;
            font-size: 27px;
            font-weight: 300;
            text-align: left;
            color: #72757c;
        }
    }

    .input-amount {
        border-radius: 13px;
        border: solid 5px #d7e1de;
        background-color: rgba(215, 225, 222, 0.39);
        margin-bottom: 10px;
    }

    .btn {
        text-transform: none;
    }
}